<template>
  <div class="container">
    <div class="container-item-upload">
      <div class="container-switch">
        <b-icon icon="link"></b-icon>
        <switches v-model="enabled" color="blue"></switches>
        <b-icon icon="cloud-upload"></b-icon>
      </div>
      <div class="containerUploadForm">
        <UploadFile
          v-if="enabled == true"  
          v-on:tipoArchivo="typeFile"
          v-on:nombreArchivo="nameFile"
          v-on:getURL="getURLFile"
        />
        <div class="container-formulario">
          <b-form @submit.prevent="onSubmit" class="d-flex">
            <b-form-select
              v-if="enabled === false"
              class="input-select"
              v-model="doc.tipo"
              :options="tipoUrl"
              placeholder="Tipo"
            ></b-form-select>
            <b-form-input
              v-if="enabled === false"
              class="input-url"
              v-model.trim="urlFile"
              type="url"
              placeholder="Ingresa la URL"
            ></b-form-input>
            <b-form-input 
              v-if="enabled === false"
              v-model="doc.nombrearchivo" 
              type="text"
              placeholder="Teclee el nombre"
            ></b-form-input>
            <template v-if="enabled">
              <b-button class="button-accept-sm button-save-url" type="submit" v-if="botonActivo">
                Guardar
              </b-button>
            </template>
            <template v-else>
              <b-button class="button-accept-sm button-save-url" type="submit" v-if="doc.tipo && urlFile">
                Guardar
              </b-button>
            </template>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import UploadFile from "../components/UploadFile";

export default {
  name: "NewDocument",
  components: {
    Switches,
    UploadFile,
  },
  props: ["seccion", "subseccion", "publicacion", "documento"],
  data() {
    return {
      ref: firebase.firestore()
        .collection("secciones").doc(this.seccion)
        .collection("subsecciones").doc(this.subseccion)
        .collection("publicaciones").doc(this.publicacion)
        .collection("documentos").doc(this.documento)
        .collection("urls"),
      doc: {},
      urlFile: "",
      enabled: false,
      tipoArchivo: "",
      nombreArchivo: "",
      botonActivo: false,
      tipoUrl: [
        { value: "", text: "Elija un Tipo de Archivo" },
        { value: "pdf", text: "PDF" },
        { value: "doc", text: "Word" },
        { value: "xls", text: "Excel" },
        { value: "ppt", text: "PowerPoint" },
        { value: "video", text: "Video" },
        { value: "audio", text: "Audio" },
        { value: "web", text: "Web" },
      ],
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.doc.url = this.urlFile;
      this.doc.edit = false;

      if (this.enabled === true) {
        this.doc.nombrearchivo = this.nombreArchivo;
        if (this.tipoArchivo === "application/pdf") {
          this.doc.tipo = "pdf"
        } else if (this.tipoArchivo === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || this.tipoArchivo === "application/msword"){
          this.doc.tipo = "doc"
        } else {
          this.doc.tipo = "otro"
        }
      }

      this.ref
        .add(this.doc)
        .then((docRef) => {
          this.doc.tipo = "",
          this.doc.nombrearchivo = "",
          this.urlFile = "",
          this.botonActivo = false,
          this.enabled = false,
          this.edit = false,
          this.nombreArchivo = ""
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    },
    typeFile: function(type) {
      console.log("Tipo de Archivo: " + type);
      this.tipoArchivo = type;
    },
    nameFile: function(name) {
      console.log("Nombre de Archivo: " + name);
      this.nombreArchivo = name;
    },
    getURLFile: function(url) {
      console.log("URL: " + url);
      this.urlFile = url;
      this.botonActivo = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
.container-item-upload {
  display: flex;
  align-items: center;

  .container-switch {
    display: flex;
    justify-content: center;
    padding: 0.75rem 0.5rem;
    .b-icon {
      margin: 0 0.5rem
    }
  }
  
}
</style>
