import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const settings = { timestampsInSnapshots: true };

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-mEi37QtjnbtXlcBb5Q6QA0BnYgKXLnA",
  authDomain: "micrositio-iil.firebaseapp.com",
  projectId: "micrositio-iil",
  storageBucket: "micrositio-iil.appspot.com",
  messagingSenderId: "20225747883",
  appId: "1:20225747883:web:8d91d8971ebcfd69a340ee",
  measurementId: "G-E9HFW912M4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(settings);

export default firebase;