<template>
  <div id="app">
    <PubHeader />
    <div class="container-router-view">
      <router-view />
    </div>
    <PubFooter />
  </div>
</template>

<script>
  import PubHeader from "@/components/PubHeader";
  import PubFooter from "@/components/PubFooter";

  export default {
    components: {
      PubHeader,
      PubFooter,
    },
  };
</script>

<style lang="scss">
@import "./scss/abstracts/variables";
.container-router-view {
    margin-top: 80px;
    min-height: calc(100vh - 180px);
    @include media-breakpoint-up(md) {
      margin-top: 90px;
    }
  }
</style>