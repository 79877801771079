<template>
  <b-nav class="menu-admin">
    <b-nav-item v-b-tooltip.hover title="Administración">
      <router-link :to="{ name: 'Admin' }">
        <svg
          class="botton-admin"
          viewBox="0 0 95.337 95.336"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g>
            <path
              class="marco"
              d="M92.938,5.78H2.4C1.076,5.78,0,6.857,0,8.18v63.438c0,1.322,1.077,2.4,2.4,2.4h90.538c1.323,0,2.399-1.078,2.399-2.4
              V8.181C95.338,6.857,94.262,5.78,92.938,5.78z M88.137,66.817H7.201V12.982h80.936V66.817z"
            />
            <path
              d="M65.072,81.756h-4.801v-4.402c0-0.663-0.539-1.2-1.201-1.2H36.267c-0.663,0-1.2,0.537-1.2,1.2v4.402h-4.801
              c-0.663,0-1.2,0.537-1.2,1.199v5.401c0,0.663,0.538,1.2,1.2,1.2h34.806c0.662,0,1.199-0.537,1.199-1.2v-5.401
              C66.271,82.293,65.734,81.756,65.072,81.756z"
            />
            <path
              d="M15.303,19.077c-1.104,0-2,0.896-2,2v16.672l18.671-18.672H15.303z"
            />
          </g>
        </svg>
      </router-link>
    </b-nav-item>

    <b-nav-item @click="logout" v-b-tooltip.hover title="Cerrar Sesión">
      <svg
        class="botton-logout"
        viewBox="0 0 512 512"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="m453.332031 512h-224c-32.363281 0-58.664062-26.304688-58.664062-58.667969v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 14.699219 11.964843 26.667969 26.664062 26.667969h224c14.699219 0 26.667969-11.96875 26.667969-26.667969v-394.664062c0-14.699219-11.96875-26.667969-26.667969-26.667969h-224c-14.699219 0-26.664062 11.96875-26.664062 26.667969v96c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-96c0-32.363281 26.300781-58.667969 58.664062-58.667969h224c32.363281 0 58.667969 26.304688 58.667969 58.667969v394.664062c0 32.363281-26.304688 58.667969-58.667969 58.667969zm0 0"
        />
        <g class="flecha">
          <path
            d="m325.332031 272h-309.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h309.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
          />
          <path
            d="m240 357.332031c-4.097656 0-8.191406-1.554687-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.636719l74.027344-74.023437-74.027344-74.027344c-6.25-6.25-6.25-16.386719 0-22.636719 6.253906-6.25 16.386719-6.25 22.636719 0l85.332031 85.335938c6.25 6.25 6.25 16.382812 0 22.632812l-85.332031 85.332032c-3.136719 3.160156-7.230469 4.714843-11.328125 4.714843zm0 0"
          />
        </g>
      </svg>
    </b-nav-item>
  </b-nav>
</template>

<script>
import firebase from "../firebase";
import router from "../router";

export default {
  name: "MenuAdmin",
  data() {
    return {};
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

.menu-admin {
  justify-content: center;
  margin-top: 6rem;
}

.botton-admin,
.botton-logout {
  width: 30px;
  height: 30px;

  &:hover {
    .marco,
    .flecha {
      fill: $color-bg-01;
    }
  }
}
</style>
