<template>
  <div>
    <b-modal
      ref="modal-avisos"
      size="lg"
      hide-footer
      hide-header
      title="Avisos"
    >
      <div class="d-block">
        <img
          class="logocongresomodal"
          src="../assets/logos/XVI-logo-anio2-gris-2020.svg"
          alt=""
        />
        <h5 class="titulo-modal">Avisos</h5>
        <div class="container container-avisos">
          <div
            class="aviso shadow-sm"
            v-for="a in avisos"
            :key="a.key"
            v-html="a.aviso"
          ></div>
        </div>
      </div>
    </b-modal>
    <div class="container-fluid container-marco-inicio">
      <div class="container-bienvenida d-flex">
        <!-- <img
          class="logoIIL"
          src="../assets/logos/LogoIIL-2022.png"
          alt="Logo del Instituto de Investigaciones Legislativas del Poder Legislativo del Estado de Quintana Roo"
        /> -->
        <div class="container-bienvenida-texto">
          <h1 class="titulo-micrositio">Bienvenidos al micrositio del Instituto de Investigaciones Legislativas.</h1>
          <!-- <p><strong>Artículo 108.</strong> El Instituto de Investigaciones Legislativas es el Órgano profesional e imparcial, con autonomía operativa y de gestión dependiente de la Junta, encargado de la realización de estudios e investigaciones jurídicas y multidisciplinarias en que se apoye la Legislatura en el ejercicio de sus atribuciones costitucionales y legales.</p> -->

          <b-button v-b-modal.modal-Bienvenida class="boton-bienvenida">Bienvenida</b-button>
          <b-modal id="modal-Bienvenida" size="xl" hide-footer>
            <div class="container-historia">
              <h4 class="TituloModalHistoria">Bienvenida del Director.</h4>
              <img class="imagen-titular" src="../assets/img-jame.jpeg" alt="Mtro. José Alberto Muñóz Escalante">
              <p>Para quienes integramos el Instituto de Investigaciones Legislativas del Poder Legislativo de Quintana Roo, nos congratula el darle la más cordial bienvenida a este sitio electrónico, que pretende ser un espacio digital, que dé a conocer las actividades de análisis, estudio e investigación legislativa, así como los foros, cursos y demás acciones académicas que realice, coordine o gestione este Instituto, siempre encaminado a impulsar la cultura parlamentaria en la Legislatura del Estado.</p>
              <p>El Micrositio del Instituto, ofrece información de la gestión y proyección que realizan de manera permanente sus dos Unidades, por un lado la de Igualdad de Género, que es el área especializada para llevar a cabo las actividades de investigación en temas relativos a la igualdad entre mujeres y hombres, siendo la rectora de la política en materia de igualdad de género, la cual se encamine al fomento de nuevas relaciones laborales entre el personal de la Legislatura, que lleven a un trato equitativo, igualitario y respetuoso.</p>
              <p>Por el otro, la Unidad de Análisis Financiero, que es el área especializada para llevar a cabo las actividades de estudio y análisis de autorizaciones de la Legislatura, cuando algún ente público requiera contraer obligaciones o financiamientos, para inversiones públicas productivas o refinanciamiento de deuda, así como la formulación de los impactos presupuestales que deben llevar los dictámenes que emita la Legislatura del Estado.</p>
              <p>Asimismo, se tiene el propósito de que, a través de este sitio de internet, se ofrezca a la población, cada una de las acciones de relevancia que la Dirección central realice al coordinarse con las diferentes áreas y comisiones que integran la Legislatura, así como con los entes externos, con los cuales hermane el trabajo para el logro de sus objetivos comunes.</p>
              <p>De igual forma, se mantendrá actualizado el acervo bibliográfico digital, de estudios, opiniones y análisis literarios en la materia parlamentaria o de interés, que nos compartan los Institutos de Investigación Legislativa de otras entidades federativas, y del Senado de la República el Instituto Belisario Domínguez, del Centro de Estudios de Investigación Parlamentaria de la Cámara de Diputados, así como autores independientes locales y foráneos, que contribuyan con nuestra labor de fomento a la cultura en la materia parlamentaria.</p>
              <p>Por último, es de resaltar que este espacio se ha diseñado, para que, de manera ágil, quienes se interesen en la materia parlamentaria, puedan acceder al acervo documental con que cuente el Instituto y que sirva en la elaboración de nuevos proyectos de análisis e investigación que interese realizar a cualquier persona.</p>

              <blockquote>“En pro del conocimiento, hacia una mejor toma de decisiones, en beneficio de la humanidad.”</blockquote>

              <h6 class="cabecera-firma">Atentamente</h6>
              <h4 class="nombre-firma">Mtro. José Alberto Muñóz Escalante.</h4>
            </div>
          </b-modal>
          <b-button v-b-modal.modal-MarcoJuridico class="boton-bienvenida">Marco Jurídico</b-button>
          <b-modal id="modal-MarcoJuridico" size="xl" hide-footer>
            <h4 class="TituloModal">Marco Jurídico</h4>
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <h3 block v-b-toggle.accordion-1>Instituto de Investigaciones Legislativas</h3>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <MarcoJuridicoIIL clase="marco-juridico"/>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <h3 block v-b-toggle.accordion-2>Unidad de Igualdad de Género</h3>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <MarcoJuridicoUIG clase="marco-juridico"/>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <h3 block v-b-toggle.accordion-3>Unidad de Análisis Financiero</h3>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <MarcoJuridicoUAF clase="marco-juridico"/>
                </b-collapse>
              </b-card>
            </div>
          </b-modal>
          <br />
          <b-button v-b-modal.modal-Historia class="boton-bienvenida">Historia del Instituto</b-button>
          <b-modal id="modal-Historia" size="xl" hide-footer>
            <div class="container-historia">
              <h4 class="TituloModalHistoria">Historia General del Instituto de Investigaciones Legislativas.</h4>
              <p>Con fundamento en lo dispuesto en el Decreto número 281 de fecha 5 de marzo del 2008 expedido por la H. XI Legislatura del Estado, publicado en el Periódico Oficial del Estado el día 10 de marzo del mismo año, se aprobó la reforma a la fracción II del artículo 81 de la Ley Orgánica del Poder Legislativo del Estado de Quintana Roo, con el objetivo de establecer la creación del Instituto de Investigaciones Legislativas como una dependencia del Poder Legislativo del Estado de Quintana Roo, siendo este un órgano profesional e imparcial, con autonomía operativa y de gestión, el cual tiene entre sus atribuciones el realizar estudios jurídicos e investigaciones jurídicas y multidisciplinarias en que se apoyan las iniciativas de Ley y normas generales que expida la Legislatura en ejercicio de sus atribuciones constitucionales y legales.</p>
              <p>Así también, cabe hacer mención que mediante el decreto número 15 de fecha 5 de junio del año 2008 expedido por la H. XII Legislatura del Estado el cual fue publicado en el Periódico Oficial el día 6 de junio del mismo año, se reformaronlos artículos 83 y 92 segundo párrafo de la Ley Orgánica del Poder Legislativo del Estado de Quintana Roo, así también según lo dispuesto por el Transitorio Segundo del Decreto Número 281 de fecha 5 de marzo de 2008, expedido por la XI Legislatura del Estado, con el propósito de que el Instituto de Investigaciones Legislativas iniciara sus funciones y se designara al primer titular de esta área, siendo así se tuvo a bien nombrar al <strong>LIC. JOSE LUIS PATRON AZUETA</strong>, en fecha 16 de octubre del 2008, quien inicio la etapa de conformación del Instituto, siendo él, el encargado de la organización y funcionamiento inicial, todo esto en conjunto con la estructura profesional, técnica y administrativa necesaria para el ejercicio de sus atribuciones.</p>
              
              <p>El 14 de Junio del 2019, mediante el decreto 302, expedido por la XV Legislatura se publica la actualización a la Ley Orgánica del Estado de Quintana Roo dando así al Instituto de Investigaciones Legislativas el carácter de órgano profesional e imparcial, con autonomía operativa y de gestión dependiente de la Junta de Gobierno y Coordinación Política, encargado de la realización de estudios e investigaciones jurídicas y multidisciplinarias en que se apoye la Legislatura en el ejercicio de sus atribuciones constitucionales y legales.</p>
              <h3>Directores Anteriores</h3>
              <ul>
                <li>
                  <h5>LIC. JOSE LUIS PATRON AZUETA</h5>
                  <span>2008 A 2013</span>
                </li>
                <li>
                  <h5>LIC. EDWIN ALEJANDRO RIVERA ROMERO</h5>
                  <span>2013 A 2016</span>
                </li>
                <li>
                  <h5>MTRO. ARTURO GUILBERT RUIZ</h5>
                  <span>2016 A 2019</span>
                </li>
                <li>
                  <h5>MTRO. ALEJANDRO FUENTES YAÑEZ</h5>
                  <span>2019 A 2022</span>
                </li>
                <li>
                  <h5>MTRO. JOSÉ ALBERTO MUÑOZ ESCALANTE</h5>
                  <span>2022 – ACTUAL DIRECTOR</span>
                </li>
              </ul>
            </div>
            
          </b-modal>
        </div>
        
      </div>
    </div>
    <PubHomeSecciones />
  </div>
</template>

<script>
import firebase from "../firebase";
//import router from "../router";
import PubHomeSecciones from "../components/PubHomeSecciones.vue"
import MarcoJuridicoIIL from "../components/MarcoJuridicoIIL.vue"
import MarcoJuridicoUIG from "../components/MarcoJuridicoUIG.vue"
import MarcoJuridicoUAF from "../components/MarcoJurídicoUAF.vue"

export default {
  name: "Home",
  components: {
    PubHomeSecciones,
    MarcoJuridicoIIL,
    MarcoJuridicoUIG,
    MarcoJuridicoUAF
  },
  data() {
    return {
      show: false,
      refAvisos: firebase.firestore().collection("avisos"),
      avisos: {},
      modal: false,
    };
  },
  created() {
    this.refAvisos.where("activo", "==", true).onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
        });
      });

      if (this.avisos.length != 0) {
        this.showModal();
      }
    });
  },
  methods: {
    showModal() {
      this.$refs["modal-avisos"].show();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

.container-marco-inicio {
  padding: 6rem 1rem 0.5rem;
  // margin-bottom: 0.5rem;
  
  background-image: url(../assets/fondo.jpg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  
  @include media-breakpoint-up(md) {
    padding: 6rem 1rem 2rem;
    // margin-bottom: 1.5rem;
  }

  .container-bienvenida {
    //width: 280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      width: 80%;
      max-width: 700px;
    }
    .logoIIL {
      height: 200px;
      @include media-breakpoint-up(md) {
        margin-right: 2rem;
      }
    }
    .container-bienvenida-texto {
      padding: 1rem 2rem;
      text-align: center;
    }
    .ilustra-inicio {
      width: 50%;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        width: 350px;
        margin-bottom: 2rem;
      }
    }
    .titulo-micrositio {
      font-family: $font-01;
      color: white;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        font-size: 1.9rem;
        margin-top: 1.75rem;
      }
    }
    p {
      font-size: 0.7rem;
      font-weight: 300;
      
      @include media-breakpoint-up(md) {
        font-size: 0.85rem;
      }
    }
    .boton-bienvenida {
      background: none;
      font-size: 0.85rem;
      padding:0.5rem 2.5rem;
      border: 1px solid white;
      border-radius: 0;
      margin: 0.5rem;
      margin-top: 0;
      @include media-breakpoint-up(md) {
        margin: 1rem;
        font-size: 1rem;
      }
      &:hover {
        background: white;
        color: black;
      }
    }

    
  }
}
.TituloModal {
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.accordion {
  margin-bottom: 2rem;
  h3 {
    font-size: 1rem;
    margin: 0.25rem;
    text-align: center;
    text-transform: uppercase;
    &:hover {
      color: $color-bg-01;
    }
  }
}

.container-historia {
  margin: 1rem;
  @include media-breakpoint-up(lg) {
    margin: 1rem 2rem;
  }
  .TituloModalHistoria {
    font-family: $font-01;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
  }
  p {
    font-family: $font-02;
    font-weight: 300;
    font-size: 1rem;
    text-align: justify;
    @include media-breakpoint-up(lg) {
      font-size: 1.15rem;
    }
  }

  h3 {
    font-family: $font-01;
    font-size: 1.15rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }
  ul {
    padding-left: 1rem;
    @include media-breakpoint-up(md) {
      padding-left: 2rem;
    }
    li {
      padding: 1rem 1.5rem;
      border-left: 1px solid black;
      h5 {
        margin: 0;
        font-size: 0.8rem;
        font-family: $font-01;
        @include media-breakpoint-up(lg) {
          font-size: 1.1rem;
        }
      }
      span {
        font-size: 0.75rem;
        font-weight: 700;
        @include media-breakpoint-up(lg) {
          font-size: 0.9rem;
        }
      }

      // &:first-child {

      //   &::before {
      //     background:red;
      //     border-color: rgb(34, 34, 34);

      //     @include media-breakpoint-up(md) {
      //       background: blue;
      //     }
      //   }
      // }

      // &:last-child {
      //   padding-bottom: 0.5rem;
      // }

      &::before {
        border: 1px solid black;
        background: #e0e0e0;
        width: 11px;
        height: 11px;
        display: block;
        position: absolute;
        margin-left: -30px;
        margin-top: 5px;
        border-radius: 50%;
        content: "";

        // //margin-top: 1px;
        // @include media-breakpoint-up(lg) {
        //   border: 2px solid yellow;
        //   width: 17px;
        //   height: 17px;
        //   margin-left: -41px;
        // }
      }
    }
  }

  .imagen-titular {
    display: block;
    width: 120px;
    margin: 0 auto;
    margin-bottom: 1rem;
    @include media-breakpoint-up(sm) {
      float: left;
      width: 150px;
      margin: 0.5rem 2rem 1rem 0.5rem;
    }
    @include media-breakpoint-up(lg) {
      width: 250px;
    }
  }
  blockquote {
    margin: 1rem;
    @include media-breakpoint-up(lg) {
      margin: 2rem 8rem 1rem;
    }
  }
  .cabecera-firma, .nombre-firma {
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
    font-size: 1.2rem;
    }
  }
  .cabecera-firma {
    margin-top: 2.5rem;
  }
}
</style>
