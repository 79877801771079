import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Admin from "../views/Admin.vue";
import AdminSeccion from "../views/AdminSeccion.vue";
import AdminSubseccion from "../views/AdminSubseccion.vue";

import firebase from "firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/seccion/:id",
    name: "Seccion",
    component: () =>
      import(/* webpackChunkName: "Seccion" */ "../views/Seccion.vue"),
  },
  {
    path: "/seccion/:seccion/subseccion/:subseccion/publicacion/:publicacion/articulo/:id",
    name: "articulo",
    component: () => import("../components/PubArticulosDetalle.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { requiresAuth: true },
  },
  {
    path: "/adminseccion/:id",
    name: "AdminSeccion",
    component: AdminSeccion,
    meta: { requiresAuth: true },
  },
  {
    path: "/adminseccion/:id/adminsubseccion/:subid",
    name: "AdminSubseccion",
    component: AdminSubseccion,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  //console.log("isauthenticated", isAuthenticated);

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;
