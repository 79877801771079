<template>
  <b-card-body :class="clase">
    <h1>CAPÍTULO SEGUNDO Del Instituto de Investigaciones Legislativas</h1>
    <p><strong>Artículo 108.</strong> El Instituto de Investigaciones Legislativas es el órgano profesional e imparcial, con autonomía operativa y de gestión dependiente de la Junta, encargado de la realización de estudios e investigaciones jurídicas y multidisciplinarias en que se apoye la Legislatura en el ejercicio de sus atribuciones constitucionales y legales. </p>
    <p>El Instituto será coordinado por un diputado que será nombrado por el pleno de la Legislatura a propuesta de la Junta. El diputado que coordine el Instituto no podrá ser al mismo tiempo presidente de la mesa directiva ni de la Junta.</p>
    <p>El instituto contará igualmente con un titular y contará para su organización y funcionamiento con la estructura profesional, técnica y administrativa necesaria para el ejercicio de sus atribuciones. La retribución y estímulos que perciban el personal profesional y técnico serán los que establezca el tabulador de sueldos que apruebe la Junta.</p>
    <p>El Instituto tendrá las atribuciones siguientes: </p>
    <ol type=I>
      <li>Establecer las directrices para la realización de los estudios e investigaciones jurídicas y multidisciplinarias en que se apoye la Legislatura del Estado en el ejercicio de sus atribuciones constitucionales y legales;</li>
      <li>Desarrollar investigaciones y análisis en temas de vanguardia, jurídicos, sociológicos, históricos, de desarrollo social, económicos, así como en materia de finanzas públicas, entre otros; </li>
      <li>Efectuar estudios y análisis comparativos de la legislación así como de carácter multidisciplinarios;</li>
      <li>Coadyuvar con las comisiones, analizando los anteproyectos de ley, reformas o adiciones que se presenten a la Legislatura;</li>
      <li>Proponer a la Junta, anteproyectos de iniciativas de leyes, reformas o adiciones, tendientes a actualizar la legislación vigente en el Estado;</li>
      <li>Coordinarse con organismos oficiales y privados en la elaboración de anteproyectos de iniciativas de leyes y decretos que correspondan a las áreas de su trabajo;</li>
      <li>Proponer a la Junta la contratación de investigadores externos que se hagan necesarios para apoyo o para desarrollar trabajos especiales que lleve a cabo el Instituto;</li>
      <li>Elaborar, a solicitud de la Junta, las convocatorias para la participación de la sociedad en foros de consulta y paneles, sobre temas jurídicos parlamentarios que sean de interés social;</li>
      <li>Asistir y participar en los seminarios, congresos y foros de consulta que se celebren en el Estado o en otras entidades que la Junta le autorice asistir; </li>
      <li>Celebrar y organizar foros, conferencias y seminarios académicos relacionados con sus funciones;</li>
      <li>Elaborar, preparar e impartir cursos de capacitación legislativa y programas de formación y capacitación de personal técnico en el desarrollo de la función legislativa previamente aprobados por la Junta;</li>
      <li>Fomentar la celebración y ejecución de convenios de colaboración e intercambio con las instancias académicas de más alto nivel estatal, nacional e internacional, para la formación o actualización de profesionales vinculados con la tarea legislativa;</li>
      <li>Promover el fortalecimiento del trabajo legislativo, a través de la creación de programas de becas que estimulen la formación académica, la investigación y el trabajo editorial;</li>
      <li>Difundir en el ámbito de su competencia los resultados del desarrollo de los estudios e investigaciones que realice, a través de publicaciones especializadas;</li>
      <li>Identificar los procedimientos con que actualmente se desarrolla la tarea legislativa, con el fin de proponer y operar sistemas, así como procesos apoyados en tecnología avanzada;</li>
      <li>Establecer mecanismos de coordinación y cooperación con institutos y entidades que realicen funciones similares;</li>
      <li>Realizar estudios de impacto social y económico de la legislación vigente, así como en proyecto, y</li>
      <li>Las demás inherentes a la consecución de las anteriores y las que expresamente le señale la Legislatura, la Junta o el Coordinador del Instituto.</li>
    </ol>
  </b-card-body>
</template>

<script>
export default {
  name: "MarcoJuridicoIIL",
  props: ["clase"]
}
</script>

<style>

</style>