<template>
  <b-card-body :class="clase">
    <h1>SECCIÓN PRIMERA De la Unidad de Igualdad de Género</h1>
    <p><strong>Artículo 109.</strong> El Instituto de Investigaciones Legislativas contará con una Unidad de Igualdad de Género, como área especializada para llevar a cabo las actividades de investigación en temas relativos a la igualdad entre mujeres y hombres, además de ser la rectora de la política en materia de igualdad de género, que fomente entre el personal nuevas relaciones laborales de manera equitativa, igualitaria y respetuosa a fin de transformar al Poder Legislativo en sus estructuras y cultura institucional. </p>
    <p>Las actividades que realice la unidad en términos de las atribuciones que esta ley le confiere, <strong>serán supervisadas por la Comisión para la Igualdad de Género del Poder Legislativo y por el titular del Instituto de Investigaciones Legislativas.</strong></p>
    <p>La Unidad de Igualdad de Género, tendrá las siguientes atribuciones:</p>
    <ol type=I>
      <li>Elaborar y someter a la aprobación de la Comisión para la Igualdad de Género, el programa anual de trabajo de la unidad, para fortalecer la igualdad de género del Poder Legislativo del Estado;</li>
      <li>Realizar y difundir investigaciones estratégicas encaminadas a contribuir en la toma de decisiones legislativas para disminuir la desigualdad entre mujeres y hombres;</li>
      <li>Elaborar el diagnóstico y el anteproyecto para adecuar sistemáticamente la normatividad existente con perspectiva de género;</li>
      <li>Brindar asesoría en materia de igualdad de género a la Legislatura;</li>
      <li>Impulsar que la selección del personal y los ascensos que se realicen por la Secretaria General del Poder Legislativo, se otorguen con base en criterios de transparencia e igualdad de género e inclusión de la diversidad social y elaborar diagnósticos que permitan identificar brechas de desigualdad de género que se presenten en la estructura organizacional del Poder Legislativo;</li>
      <li>Coadyuvar a las comisiones ordinarias, cuando éstas así lo consideren con la asesoría en sus anteproyectos de iniciativas de ley en materia de igualdad de género;</li>
      <li>Elaborar el protocolo para la prevención, atención y sanción del hostigamiento y acoso sexual en el Poder Legislativo, mismo que deberá ser sometido a aprobación de la Legislatura;</li>
      <li>Realizar acciones con las organizaciones de la sociedad civil e instituciones académicas que trabajen temas de igualdad de género con el fin de vincularlas con la labor que realiza la unidad;</li>
      <li>Elaborar, preparar e impartir cursos de capacitación enfocados a fortalecer la igualdad de género dentro del Poder Legislativo, y</li>
      <li>Las demás inherentes a la consecución de las anteriores y las que expresamente le señale la Legislatura, la Comisión para la Igualdad de Género, el Coordinador y el titular del Instituto.</li>
    </ol>
  </b-card-body>
</template>

<script>
export default {
  name: "MarcoJuridicoUIG",
  props: ["clase"]
}
</script>

<style>

</style>