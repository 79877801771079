<template>
  <div>
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <ul v-else>
      <li class="mb-4"
        v-for="i in items" :key="i.key"
      >
        <div v-if="i.edit === false" class="d-flex align-items-center lista-secciones">
          <span class="item-orden">{{ i.orden }}</span>
          <p class="titulo-documento-admin">
            <span v-if="i.fecha">{{ i.fecha }}. </span>
            {{ i.titulo }}
            <b-button
              class="button-edit"
              v-b-tooltip.hover
              title="Editar"
              @click="setEditDocument(i.key)"
            >
              <b-icon icon="pen" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              class="button-delete"
              @click.stop="deleteDocument(i.key)"
              v-b-tooltip.hover
              title="Eliminar"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
          </p>
        </div>
      
        <div v-else class="d-flex align-items-center lista-secciones">
          <b-form-input
            class="input-orden"
            v-model.number="i.orden"
            type="number"
            placeholder="Orden"
          ></b-form-input>
          <b-form-textarea
            class="input-textarea"
            v-model.trim="i.titulo"
            type="text"
            placeholder="Título"
          ></b-form-textarea>
          <b-form-input
          class="input-fecha"
            v-model="i.fecha"
            type="date"
            placeholder="fecha (opcional)"
          ></b-form-input>
          <div class="container-botton-inline">
            <b-button class="button-accept-sm" @click="setUpdateDocument(i)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
              </svg>
            </b-button>
            <b-button class="button-cancel-sm" @click="cancelEditDocument(i.key)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg>
            </b-button>
          </div>
        </div>

        <template v-if="i.edit === false">
          <div class="d-flex lista-secciones">
            <div class="container-nuevo-link">
              <AdminUrlNueva :seccion="seccion" :subseccion="subseccion" :publicacion="publicacion" :documento="i.key"/>
            </div>
            <AdminUrlLista :seccion="seccion" :subseccion="subseccion" :publicacion="publicacion" :documento="i.key" />
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "../firebase";
import router from "../router";
import AdminDocumentoNuevo from "./AdminDocumentoNuevo.vue"
import AdminUrlNueva from "./AdminUrlNueva.vue"
import AdminUrlLista from "./AdminUrlLista.vue"

export default {
  name: "AdminSubseccionLista",
  props: ["seccion", "subseccion", "publicacion"],
  components: {
    AdminDocumentoNuevo,
    AdminUrlNueva,
    AdminUrlLista,
  },
  data() {
    return {
      ref: firebase.firestore().collection("secciones").doc(this.seccion).collection("subsecciones").doc(this.subseccion).collection("publicaciones").doc(this.publicacion).collection("documentos"),
      items: {},
      showEdit: true,
      enabled: false,
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    details(subseccion) {
      router.push({ name: "AdminSubseccion", params: { id: this.seccion, subid: subseccion } });
    },
    setEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    setUpdateDocument(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          titulo: id.titulo,
          orden: id.orden,
          fecha: id.fecha,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteDocument(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#657f61",
        cancelButtonColor: "#99494b",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.seccion)
            .collection("subsecciones")
            .doc(this.subseccion)
            .collection("publicaciones")
            .doc(this.publicacion)
            .collection("documentos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>
