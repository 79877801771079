<template>
  <b-card-text :class="clase">
    <h1>SECCIÓN SEGUNDA De la Unidad de Análisis Financiero</h1>
    <p><strong>Artículo 110.</strong> El Instituto de Investigaciones Legislativas contará con una Unidad de Análisis Financiero, como área especializada para llevar a cabo las actividades de análisis relativas a solicitudes de autorización a la Legislatura, que efectúen los entes públicos cuando requieran contraer Obligaciones o Financiamientos cuyo destino sean las Inversiones públicas productivas, el Refinanciamiento o la Reestructura de Deuda así también formulará los impactos presupuestarios que deberá llevar implícito cada dictamen que emita la Legislatura del Estado, en cumplimiento a la Ley de Disciplina Financiera de las Entidades Federativas y los Municipios. </p>
    <p>Las actividades que realice la Unidad en términos de las atribuciones que esta Ley le confiere, serán el soporte técnico de la Comisión de Hacienda, Presupuesto y Cuenta del Poder Legislativo, cuando le sean turnadas este tipo de solicitudes de autorización. </p>
    <p>La Unidad de Análisis Financiero del Poder Legislativo, tendrá las siguientes funciones: </p>
    <ol type=I>
      <li>Realizar los impactos presupuestarios y procurar que estos se incluyan en los dictámenes que se aprueben por la Legislatura;</li>
      <li>Realizar análisis de la capacidad de pago del ente público a cuyo cargo estaría la Deuda Pública u Obligaciones correspondientes;</li>
      <li>Identificar el destino de los recursos de la Deuda Pública u Obligación que se pretenda por los entes públicos;</li>
      <li>Señalar en su caso, la fuente de pago o garantía de pago de las obligaciones que contraerá;</li>
      <li>Requerir a los entes públicos, la información relativa a las solicitudes de autorización en materia de deuda pública cuando pretendan contraer obligaciones o financiamientos cuyo destino sean las Inversiones públicas productivas, el refinanciamiento o la reestructura de deuda;</li>
      <li>Solicitar información a diversas instancias del orden público sea federal, estatal o municipal que abone al análisis de la capacidad de pago del ente tenga como pretensión el contraer obligaciones o financiamientos, y</li>
      <li>Auxiliar a la Comisión de Hacienda, Presupuesto y Cuenta en el análisis de iniciativas de ley de ingresos y egresos estatal o de ingresos municipales de entes públicos, que se encuentren vinculados a la previa contratación de deuda pública.</li>
    </ol>
  </b-card-text>
</template>

<script>
export default {
  name: "MarcoJuridicoUAF",
  props: ["clase"]
}
</script>

<style>

</style>