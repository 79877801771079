<template>
  <div>
    <!--<code>&lt;AdminPublicacionLista.vue&gt;</code>-->
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <ul v-else>
      <li
        v-for="i in items" :key="i.key"
      >
        <div class="d-flex align-items-center lista-secciones" >
          <template v-if="i.edit === false" >
            <span class="item-orden">{{ i.orden }}</span>
            <template>
              <h2 class="titulo-publicacion" v-if="i.titulo">{{ i.titulo }}</h2>
              <h2 class="titulo-publicacion" v-else>Sin subtitulo</h2>
            </template>
            <div class="container-botones">
              <b-button
                class="button-link"
                v-b-tooltip.hover
                title="Agregar"
                role="tab"
                v-b-toggle="'accordion-' + i.key"
              >
                <b-icon icon="filter-circle"></b-icon>
              </b-button>
              <b-button
                class="button-edit"
                v-b-tooltip.hover
                title="Editar"
                @click="setEditDocument(i.key)"
              >
                <b-icon icon="pen"></b-icon>
              </b-button>
              <b-button
                class="button-delete"
                @click.stop="deleteDocument(i.key)"
                v-b-tooltip.hover
                title="Eliminar"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template v-else>
            <b-form-input
              class="input-orden"
              v-model.number="i.orden"
              type="number"
              placeholder="Orden"
            ></b-form-input>
            <b-form-input
              v-model.trim="i.titulo"
              type="text"
              placeholder="Título"
            ></b-form-input>
            
            <div class="container-botton-inline">
              <b-button class="button-accept-sm" @click="setUpdateDocument(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                </svg>
              </b-button>
              <b-button class="button-cancel-sm" @click="cancelEditDocument(i.key)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                  <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                </svg>
              </b-button>
            </div>
          </template>
        </div>
        <b-collapse :id="'accordion-' + i.key" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-tabs class="tabs-publicacion">

              <b-tab class="tab-publicacion" title="Documentos" lazy>
                <div class="container-button-add" v-if="!show">
                  <BotonNuevo texto="Agregar Documento" @accion="activar" />
                </div>
                <div v-if="show" class="container-new-document">
                  <AdminDocumentoNuevo 
                    :seccion="seccion" 
                    :subseccion="subseccion" 
                    :publicacion="i.key"
                    @accion="cancelarDocumento"
                  />
                </div>
                
                <AdminDocumentoLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key"/>
              </b-tab>

              <b-tab title="Imágenes" lazy>
                <div class="container-button-add" v-if="!showFoto">
                  <BotonNuevo texto="Agregar Fotografía" @accion="activarEdicionFoto" />
                </div>
                <div v-if="showFoto" class="container-new-foto">
                  <AdminFotoNueva 
                    :seccion="seccion" 
                    :subseccion="subseccion" 
                    :publicacion="i.key"
                    @accion="cancelarEdicionFoto"
                  />
                </div>
                <AdminFotoLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key" />
              </b-tab>

              <b-tab title="Artículo" lazy>
                <div class="container-button-add" v-if="!showArticulo">
                  <BotonNuevo texto="Agregar Artículo" @accion="activarEdicionArticulo" />
                </div>
                <div v-if="showArticulo" class="container-new-article">
                  <AdminArticuloNuevo 
                    :seccion="seccion" 
                    :subseccion="subseccion" 
                    :publicacion="i.key"
                    @accion="cancelarEdicionArticulo"
                  />
                </div>
                <AdminArticuloLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key" />
              </b-tab>
              <b-tab title="Links" lazy>
                <div class="container-button-add" v-if="!showLink">
                  <BotonNuevo texto="Agregar Link" @accion="activarEdicionLink" />
                </div>
                <div v-if="showLink" class="container-new-article">
                  <AdminLinkNuevo 
                    :seccion="seccion" 
                    :subseccion="subseccion" 
                    :publicacion="i.key"
                    @accion="cancelarEdicionLink"
                  />
                </div>
                <AdminLinkLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key" />
              </b-tab>
              <b-tab title="Links Foto" lazy>
                <div class="container-button-add" v-if="!showLink">
                  <BotonNuevo texto="Agregar Link con Foto" @accion="activarEdicionLinkFoto" />
                </div>
                <div v-if="showLinkFoto" class="container-new-article">
                  <AdminLinkFotoNueva 
                    :seccion="seccion" 
                    :subseccion="subseccion" 
                    :publicacion="i.key"
                    @accion="cancelarEdicionLinkFoto"
                  />
                </div>
                <AdminLinkFotoLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key" />
              </b-tab>
            </b-tabs>
            </b-tabs>
          </b-card-body>
        </b-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "../firebase";
import router from "../router";
import AdminDocumentoNuevo from "../components/AdminDocumentoNuevo.vue"
import AdminDocumentoLista from "../components/AdminDocumentoLista.vue"
import AdminArticuloNuevo from "../components/AdminArticuloNuevo.vue"
import AdminArticuloLista from "../components/AdminArticulolista.vue"
import AdminFotoNueva from "../components/AdminFotoNueva.vue"
import AdminFotoLista from "../components/AdminFotoLista.vue"
import AdminLinkNuevo from "../components/AdminLinkNuevo.vue"
import AdminLinkLista from "../components/AdminLinkLista.vue"
import AdminLinkFotoNueva from "../components/AdminLinkFotoNueva.vue"
import AdminLinkFotoLista from "./AdminLinkFotoLista.vue"
import BotonNuevo from "../components/BotonNuevo.vue"

export default {
  name: "AdminSubseccionLista",
  props: ["seccion", "subseccion"],
  components: {
    AdminDocumentoNuevo,
    AdminDocumentoLista,
    AdminArticuloNuevo,
    AdminArticuloLista,
    AdminFotoNueva,
    AdminFotoLista,
    AdminLinkNuevo,
    AdminLinkLista,
    AdminLinkFotoNueva,
    AdminLinkFotoLista,
    BotonNuevo,
  },
  data() {
    return {
      ref: firebase.firestore().collection("secciones").doc(this.seccion).collection("subsecciones").doc(this.subseccion).collection("publicaciones"),
      items: {},
      showEdit: true,
      show: false,
      showArticulo: false,
      showFoto: false,
      showLink: false,
      showLinkFoto: false,
      enabled: false,
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    cancelarDocumento() {
      this.show = false;
    },
    activar() {
      this.show = true;
    },
    activarEdicionArticulo() {
      this.showArticulo = true;
    },
    cancelarEdicionArticulo() {
      this.showArticulo = false;
    },
    activarEdicionFoto(){
      this.showFoto = true;
    },
    cancelarEdicionFoto() {
      this.showFoto = false;
    },
    activarEdicionLink() {
      this.showLink = true;
    },
    cancelarEdicionLink() {
      this.showLink = false;
    },
    activarEdicionLinkFoto() {
      this.showLinkFoto = true;
    },
    cancelarEdicionLinkFoto() {
      this.showLinkFoto = false;
    },
    details(subseccion) {
      router.push({ name: "AdminSubseccion", params: { id: this.seccion, subid: subseccion } });
    },
    setEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    setUpdateDocument(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          titulo: id.titulo,
          orden: id.orden,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteDocument(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#657f61",
        cancelButtonColor: "#99494b",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.seccion)
            .collection("subsecciones")
            .doc(this.subseccion)
            .collection("publicaciones")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>


