<template>
  <div>
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <ul v-else>
      <li class="mb-4 lista-fotos"
        v-for="i in items" :key="i.key"
      >
        <div class="container-foto">
          <img class="foto" :src="i.url" alt="">
          <template v-if="i.edit === false" >
            <div class="botones-edicion-foto">
              <span class="ordenFoto">{{ i.orden }}</span>
              <b-button
                class="button-edit"
                v-b-tooltip.hover
                title="Editar"
                @click="setEditArticle(i.key)"
              >
                <b-icon icon="pen" aria-hidden="true"></b-icon>
              </b-button>
              <b-button
                class="button-delete"
                @click.stop="deleteDocument(i.key)"
                v-b-tooltip.hover
                title="Eliminar"
              >
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </b-button>
            </div>
            <span v-if="i.descripcion">{{ i.descripcion }}</span>
          </template>

          <template v-else>
            <div class="container-details-articulo">
              <b-form-input
                class="input-orden-foto"
                v-model.number="i.orden"
                type="number"
                placeholder="Orden"
              ></b-form-input>
              <b-form-input
                class="input-descripcion-foto"
                v-model="i.descripcion"
                type="text"
                placeholder="Descripción"
              ></b-form-input>
              <div class="container-botton-inline">
                <b-button class="button-accept-sm btn-fotos" @click="setUpdateArticle(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                  </svg>
                </b-button>
                <b-button class="button-cancel-sm btn-fotos" @click="cancelEditArticle(i.key)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                    <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                  </svg>
                </b-button>
              </div>
            </div>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "../firebase";
import router from "../router";
import Switches from "vue-switches";

export default {
  name: "AdminArticuloLista",
  props: ["seccion", "subseccion", "publicacion"],
  components: {
    Switches,
  },
  data() {
    return {
      ref: firebase.firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("fotos"),
      items: {},
      showEdit: true,
      enabled: false,
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    details(subseccion) {
      router.push({ name: "AdminSubseccion", params: { id: this.seccion, subid: subseccion } });
    },
    setEditArticle(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    setUpdateArticle(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          orden: id.orden,
          descripcion: id.descripcion,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditArticle(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteDocument(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#657f61",
        cancelButtonColor: "#99494b",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.seccion)
            .collection("subsecciones")
            .doc(this.subseccion)
            .collection("publicaciones")
            .doc(this.publicacion)
            .collection("fotos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";

  .lista-fotos {
    display: inline-flex;

    .container-foto {
      margin: 1rem;
      display: grid;
      text-align: center;

      .foto {
        width: 300px;
        height: 200px;
        object-fit: cover;

        span {
          font-weight: 700;
        }
      }
      .ordenFoto {
        background: black;
        color: white;
        border-radius: 10px;
        padding: 0.1rem 0.5rem;
        font-size: 0.8rem;
        font-weight: 700;
        margin: 0 0.5rem;
      }
    }
  }
</style>