<template>
  <b-button @click="activar">{{texto}}</b-button>
</template>

<script>
export default {
  name: "BotonNuevo",
  props: {
    texto: String
  },
  methods: {
    activar() {
      this.$emit('accion')
    }
  }
}
</script>

<style>

</style>