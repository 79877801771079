<template>
  <div class="container">
    <div class="container-item-upload">
      <div class="container-switch">
        <b-icon icon="link"></b-icon>
        <switches v-model="enabled" color="blue"></switches>
        <b-icon icon="cloud-upload"></b-icon>
      </div>
      <div class="containerUploadForm">
        <UploadImagen
          v-if="enabled == true"  
          v-on:tipoArchivo="typeFile"
          v-on:nombreArchivo="nameFile"
          v-on:getURL="getURLFile"
          folder="album"
          :publicacion="publicacion"
        />
        <div class="container-formulario">
          <b-form @submit.prevent="onSubmit" class="d-flex">
            <b-form-input
              class="input-orden-new"
              v-model.number="foto.orden"
              type="number"
              placeholder="Orden"
            ></b-form-input>
            <b-form-input
              v-if="enabled === false"
              class="input-url"
              v-model.trim="urlFile"
              type="url"
              placeholder="Ingresa la URL"
            ></b-form-input>
            <b-form-input
              v-model="foto.descripcion" 
              type="text"
              placeholder="Teclee breve descripcion de la foto"
            ></b-form-input>
            <div class="container-botton-inline">
              <template v-if="enabled == true">
                <b-button v-if="typeFile && foto.orden" class="button-accept-sm" type="submit">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                  </svg>
                </b-button>
              </template>
              <template v-else>
                <b-button v-if="foto.orden && urlFile" class="button-accept-sm" type="submit">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                  </svg>
                </b-button>
              </template>
              
              <b-button class="button-cancel-sm" @click="cancelar">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                  <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                </svg>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import UploadImagen from "../components/UploadImagen.vue";

export default {
  name: "AdminFotoNueva",
  components: {
    Switches,
    UploadImagen,
  },
  props: ["seccion", "subseccion", "publicacion", "documento"],
  data() {
    return {
      ref: firebase.firestore()
        .collection("secciones").doc(this.seccion)
        .collection("subsecciones").doc(this.subseccion)
        .collection("publicaciones").doc(this.publicacion)
        .collection("fotos"),
      foto: {},
      urlFile: "",
      enabled: false,
      tipoArchivo: "",
      nombreArchivo: "",
      botonActivo: false,
      tipoUrl: [
        { value: "", text: "Elija un Tipo de Archivo" },
        { value: "jpg", text: "JPEG" },
        { value: "jpeg", text: "JPG" },
        { value: "png", text: "PNG" },
        { value: "web", text: "Web" },
      ],
    };
  },
  methods: {
    cancelar() {
      this.$emit('accion')
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.foto.url = this.urlFile;
      this.foto.edit = false;

      if (this.enabled === true) {
        this.foto.nombrearchivo = this.nombreArchivo;
        if (this.tipoArchivo == "image/jpeg") {
          this.foto.tipo = "jpeg"
        } else if (this.tipoArchivo === "image/jpg"){
          this.foto.tipo = "jpg"
        } else if (this.tipoArchivo === "image/png"){
          this.foto.tipo = "png"
        } else {
          this.foto.tipo = "img"
        }
      }

      this.ref
        .add(this.foto)
        .then((docRef) => {
          this.foto.orden = null,
          this.foto.tipo = "",
          this.foto.descripcion = "",
          this.urlFile = "",
          this.botonActivo = false,
          this.enabled = false,
          this.edit = false,
          this.nombreArchivo = ""
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    },
    typeFile: function(type) {
      //console.log("Tipo de Archivo: " + type);
      this.tipoArchivo = type;
    },
    nameFile: function(name) {
      //console.log("Nombre de Archivo: " + name);
      this.nombreArchivo = name;
    },
    getURLFile: function(url) {
      //console.log("URL: " + url);
      this.urlFile = url;
      this.botonActivo = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
.container-item-upload {
  display: flex;
  align-items: center;

  .container-switch {
    display: flex;
    justify-content: center;
    padding: 0.75rem 0.5rem;
    .b-icon {
      margin: 0 0.5rem
    }
  }
  
}
</style>
