<template>
  <img class="navbar-brand logoIIL" src="../assets/logos/logo-iil-version-2-01.svg"
    alt="Logo del Instituto de Investigaciones Legislativas del Poder Legislativo del Estado de Quintana Roo" />
</template>

<script>
export default {
  name: "AtomLogoIIL"
}
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";
  .logoIIL {
    height: 50px;
    width: auto;
    margin: 0 0.5rem;

    @include media-breakpoint-up(md){
      height: 80px;
    }
  }
</style>